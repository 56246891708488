import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";

import Logotype from "@ui/Logotype";

const navigation = [
  { name: "Les Gynécologues", href: "/#gynecologues" },
  { name: "Sage-femme", href: "/#sage-femme" },
  { name: "Accès et contact", href: "#directions" },
  {
    name: "Lien utiles",
    links: [
      {
        name: "Chartre CNOM",
        to: "/chartre-cnom",
      },
      {
        name: "Annuaire CNOM",
        href: "https://www.conseil-national.medecin.fr/annuaire",
      },
      {
        name: "E-Santé",
        href: "https://www.conseil-national.medecin.fr/patient",
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <div className="mx-auto max-w-7xl">
        <div className="px-6 pt-6 lg:pl-8 lg:pr-0">
          <nav
            className="flex items-center justify-between"
            aria-label="Global"
          >
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">
                Cabinet de gynécologie Gynécontades
              </span>
              <Logotype className="w-48 lg:w-72" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <Popover.Group className="hidden lg:flex lg:gap-x-3">
              {navigation.map((nav, i) =>
                nav.links ? (
                  <Popover key={i} className="relative">
                    <Popover.Button className="flex items-center gap-x-1 rounded-md bg-slate-50/90 px-3 text-sm leading-6 text-slate-500 outline outline-1 outline-slate-500/50 transition-all hover:bg-slate-50/70">
                      {nav.name}
                      <ChevronDownIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-md  bg-slate-50/90 shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-2">
                          {nav.links.map((item) => (
                            <div
                              key={item.name}
                              className="group relative flex items-center gap-x-6 rounded-md p-3 text-sm leading-6 hover:bg-gray-50"
                            >
                              <div className="flex-auto">
                                {item.href && (
                                  <a
                                    href={item.href}
                                    className="block text-sm leading-6 text-slate-500"
                                  >
                                    {item.name}
                                    <span className="absolute inset-0" />
                                  </a>
                                )}
                                {item.to && (
                                  <Link
                                    to={item.to}
                                    className="block text-sm leading-6 text-slate-500"
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                ) : (
                  <a
                    key={nav.name}
                    href={nav.href}
                    className="rounded-md bg-slate-50/90 px-3 text-sm leading-6 text-slate-500 outline outline-1 outline-slate-500/50 transition-all hover:bg-slate-50/70"
                  >
                    {nav.name}
                  </a>
                ),
              )}
            </Popover.Group>
          </nav>
        </div>
      </div>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 h-min w-full overflow-y-auto rounded-xl bg-white px-6 py-6 shadow-md sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">
                Cabinet de gynécologie Gynécontades
              </span>
              <Logotype className="w-48" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((nav, i) =>
                  nav.links ? (
                    <Popover key={i} className="relative">
                      <Popover.Button className="-mx-3 flex items-center rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-gray-50">
                        {nav.name}
                        <ChevronDownIcon
                          className="h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="z-10 mt-3 max-w-md overflow-hidden rounded-lg bg-slate-50/90">
                          <div className="p-2">
                            {nav.links.map((item) => (
                              <div
                                key={item.name}
                                className="group relative flex items-center gap-x-6 rounded-md p-3 text-sm leading-6 hover:bg-gray-50"
                              >
                                <div className="flex-auto">
                                  {item.href && (
                                    <a
                                      href={item.href}
                                      className="block text-sm leading-6 text-slate-500"
                                    >
                                      {item.name}
                                      <span className="absolute inset-0" />
                                    </a>
                                  )}
                                  {item.to && (
                                    <Link
                                      to={item.to}
                                      className="block text-sm leading-6 text-slate-500"
                                    >
                                      {item.name}
                                    </Link>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ) : (
                    <a
                      key={nav.name}
                      href={nav.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {nav.name}
                    </a>
                  ),
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
