import * as React from "react";

import Header from "@components/Header/Header";
import Footer from "@components/Footer/Footer";

export default function Layout({ children }: any) {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
