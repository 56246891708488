import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Logotype from "@ui/Logotype";

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "sa_2" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, webpOptions: { quality: 100 })
        }
      }
    }
  `);
  return (
    <footer id="directions" className="relative bg-gray-900">
      <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt=""
          className="h-full w-full object-cover brightness-125"
        />
        <svg
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <Logotype className="w-64 stroke-white" />
          <p className="mt-6 text-xl font-semibold tracking-tight text-white">
            Accès et contact
          </p>
          <address className="mt-2 text-base leading-7 text-gray-300 !no-underline">
            Gynécontades
            <span className="text-xs"> - cabinet de gynécologie</span>
            <br />
            1 Rue Gustave Klotz
            <br />
            67000 Strasbourg
            <br />
            <a href="tel:+33388365757" className="underline">
              tél: 0388365757
            </a>
            <br />
            <p>Tram : Gallia et Parc des Contades (7min. à pied)</p>
          </address>

          <div className="my-8">
            <a
              href="https://maps.app.goo.gl/3eV1mrFgrXj3vBga8"
              className="inline-flex rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Ouvrir dans Google Map
            </a>
          </div>
          {[
            {
              item: "Annuaire CNOM",
              href: "https://www.conseil-national.medecin.fr/annuaire",
            },
            {
              item: "E-Santé",
              href: "https://www.conseil-national.medecin.fr/patient",
            },
          ].map((it, i) => (
            <a
              key={i}
              href={it.href}
              target="_blank"
              className="mr-3 rounded-md text-sm text-gray-300 underline"
            >
              {it.item}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}
